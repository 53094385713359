@font-face {
    font-family: "Poppins-Bold";
    src: url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Regular";
    src: url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Light";
    src: url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Medium";
    src: url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

.superfanWraper *{
    color: white;
}

.superfanWraper input::placeholder{
    color: rgba(255, 255, 255, 0.295);
}

.toSameBorderRadius{
    border-radius: 0.2rem;
}

.superfanWraper h1{
    font-family: 'Poppins-Bold' !important;
    font-size: 1.5rem !important;
    margin: 0 !important;
}

.superfanWraper h2{
    font-family: 'Poppins-Bold' !important;
    font-size: 1.2rem !important;
    margin: 0 !important;
}

.superfanWraper h5{
    font-family: 'Poppins-Regular' !important;
    font-size: 0.9rem !important;
    margin: 0 !important;
}

.superfanWraper h6{
    font-family: 'Poppins-Regular' !important;
    font-size: 0.6rem !important;
    margin: 0 !important;
}


.superfanWraper h3{
    font-family: 'Poppins-Light' !important;
    font-size: 1rem !important; 
    margin: 0 !important;
}

.superfanWraper p{
    font-family: 'Poppins-Light' !important;
    font-size: 0.8rem !important;
    font-weight: 100 !important;
    line-height: 1.25 !important;
    margin: 0 !important;
}

.lottieContainer *{
    fill: rgba(255, 255, 255, 1);
}

#superfanTerms p{
    font-size: 0.36rem !important;
}