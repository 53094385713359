* {
  box-sizing: border-box;
  margin: 0;
  touch-action: none;
}

.App {
  text-align: center;
}

.imgContainer img{
  width: 100%;
}

.imgContainer-h{
  width: fit-content;
  z-index: 1;
}

.imgContainer-h img{
  height: 100%;
}
