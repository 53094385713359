@font-face {
  font-family: "HelveticaLTPro-Bold";
  src: url(/public/fonts/HelveticaLTPro-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "HelveticaLTPro-Black";
  src: url(/public/fonts/HelveticaLTPro-Black.ttf) format("truetype");
}
@font-face {
  font-family: "HelveticaLTPro-Roman";
  src: url(/public/fonts/HelveticaLTPro-Roman.ttf) format("truetype");
}
/* CourierPrime-Regular */

@font-face {
  font-family: 'CourierPrime-Regular';
  src: url(/public/fonts/CourierPrime-Regular.ttf) format('truetype');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  font-family: 'CourierPrime-Regular';
}